<template>
<div class="wrapper">
<!-- Navbar --> 
<topbar2></topbar2>
    <!-- /.navbar -->
  <!-- Main Sidebar Container -->
  <header2></header2>
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="">
      <div class="row">    
              <div class="col-md-8 col-12">
                 <div class="card-header">
                <h3 class="card-title"> Staff List </h3>
              </div>
              </div>
              <div class="col-md-4 col-12">
                 <div class="srcpadmobie"> 
                 <div class="row">
              <div class="col-md-9 col-9">
                <input class="form-control topmsrc5 topm5" type="text" v-model="searchQuery" placeholder="Search" />
              </div>                         
               <div v-if="this.action.includes('13')" class="col-md-3 col-3 txtright">
                    <router-link to="/addstaff" @click.prevent="toggleMenu">
                    <div class="btn btn-primary btn-sm topmsrc5 topm5"><i class="fa fa-plus nav-icon"></i> Add </div>
                    </router-link>
                  </div>
                 </div>
                 </div>
               </div>
               
            </div>
     <div class="hgtblank">&nbsp;</div>
    </div>
    </div>
    
        <!-- Main content -->
    <section class="content">
      <div class="container-fluid">        
        <!-- Main row -->
        <div class="row">
          <!-- Left col -->
          <section class="col-lg-12 connectedSortable">
            <!-- Custom tabs (Charts with tabs)-->
            <div class="">
              <div class="">               
              </div><!-- /.card-header -->
              <div class="">
                <div class="tab-content p-0">
                  <!-- Morris chart - Sales -->
                  <div class="chart tab-pane active">

                          <div class="row">
<div class="col-md-12">
<div class="card card-primary cardpad">              
              <!-- /.card-header -->
<div class="card-body">

<table class="table">
  <tr>
    <th>Name</th>
    <th>Username</th>
    <th>Role</th>
    <th>Status</th>
    <th>Action</th>
  </tr>
  <tr v-for="d in filteredResources" v-bind:key="d.id">
    <td>{{ d.name }}</td>
    <td>{{ d.user_id }}</td>
    <td>{{ d.role_name }}</td>
    <td>
  <button v-if="d.status == '1'" class="btn btn-success btn-xs"><i class="fas fa-check-circle"></i> Active </button>
  <button v-if="d.status == '0'" class="btn btn-danger btn-xs"><i class="fa fa-times-circle"></i> Deactive </button>
    </td>
    <td>
 <div v-if="this.action.includes('14') && this.action.includes('28')">
<div class="row">
<div class="col-lg-12 col-xs-12">
<router-link v-if="this.action.includes('14')" class="btn btn-success btn-xs" :to="{path:'/editstaff',query:{staff_id: d.id}}">
<i class="fa fa-edit nav-icon"></i>
</router-link> 
&nbsp;
<router-link v-if="this.action.includes('28')" class="btn btn-info btn-xs" :to="{path:'/staffchangepassword',query:{staff_id: d.id}}">
<i class="fas fa-unlock-alt"></i>
</router-link>  
&nbsp;
<button v-if="d.id != 1 && this.action.includes('14')" @click="removeData(d.id, index)" class="btn btn-danger btn-xs"> <i class="fa fa-times-circle"></i> </button>
</div>
</div>
</div>
    </td>
  </tr>
</table>


               
              </div>
              <!-- /.card-body -->
            </div>

        </div>
      </div>  
 
                   </div>                  
                </div>
              </div><!-- /.card-body -->
            </div>
            <!-- /.card -->
          </section>
          <!-- /.Left col -->
        </div>
        <!-- /.row (main row) -->
      </div><!-- /.container-fluid -->
    </section>  
  </div>
  <footer2></footer2> 
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'
import "@ocrv/vue-tailwind-pagination/dist/style.css";
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default { 
  extends:defineComponent, 
  components:{            
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar    
      },
  data() {
    return {
      resourcesaction:[],
      resources: [],
      action:[],
      myData: null,
      showbtn:this.id,
      searchQuery:'',
      user_id: this.$store.state.auth.user.data[0].id,
      company_id:this.$store.state.auth.user.data[0].company_id,
      role_id:this.$store.state.auth.user.data[0].role_id,        
    };
  },
  computed: {    
    filteredResources (){
      if(this.searchQuery){
      return this.resources.filter((d)=>{       
        return d.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      })
      }else{
        return this.resources;
      }
    }
  },
  methods: { 
    showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
     showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     },   
    async removeData(id, index){   
      if(confirm("Do you really want to delete?")){   
      axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/removestaff', {
              user_id: this.user_id,
              staff_id: id,
              company_id: this.company_id
            })
                .then(response => {                  
                  this.resources.splice(index,1); 
                  this.showsuccessmsg(response.data.response);   
                  this.$router.push({path:'/stafflist'})                 
                  console.log(response.data.response)               
                })
                .catch(error => {        
                    console.log(error)
                    this.showfailedmsg('Something went wrong');    
            })

        }  
    }, 
    async getAction() {
          var response = await axios.post(
            `http://laundry.ezeehost.com:4909/ClientIspmateApi/getroleeditscreen`, {
              company_id: this.company_id, 
              user_id: this.user_id,
              role_id: this.role_id         
        })      
              this.resourcesaction = response.data;               
              this.action = response.data.data[0].action_ids;   
              this.role_name = response.data.data[0].role_name;             
        },   
    async getData() {
      var response = await axios.post(
        `http://laundry.ezeehost.com:4909/ClientIspmateApi/get_staff_list`, {
          company_id: this.company_id          
        })      
      this.resources = response.data.data;
      this.total = response.data.total_record;
      console.log(this.resources)
    }, 
    show(id){     
      this.showbtn = id;
    },
    showclose(){          
      this.showbtn = false;
    }    
  },
  mounted(){
    setTimeout(() => {
      this.myData = 'Example Data';
    }, 2000); 
    this.getData();
    this.getAction();
  }
};
</script>

<style>
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 1.25rem 1.25rem 1.25rem !important;
}
.txtright { text-align: right;}  
  @import '../../assets/dist/css/custom.css'  
</style>